import { Typeform } from '@typeform/api-client';
import { User } from './user.entity';
import { MediaItem } from './media-item.entity';
import { BaseEntity } from './shared/base-entity';
import { Lead } from './shared/lead';
import { EmrSyncData } from './shared/emr-sync-data';
import { PurchaseOption } from './commerce/purchase-option';

export enum Resolution {
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum DiseaseCategory {
  HairLoss = 'hairLoss',
  Skincare = 'skincare',
  ErectileDysfunction = 'erectileDysfunction',
  WeightLoss = 'weightLoss',
  PrematureEjaculation = 'prematureEjaculation',
}

export enum FollowUpType {
  Initial = 'initial',
  General = 'general',
  Calibration = 'calibration',
  OutcomeAssessment = 'outcomeAssessment',
}

export interface ResolutionData {
  resolution: Resolution;
  resolvedBy: User;
  date: string;
  message: string | null;
  evaluationAndAction: string;
  emrSyncData: EmrSyncData | null;
}

export interface CompletionData {
  completedBy: User;
  date: string;
  message: string;
  evaluationAndAction: string;
  emrSyncData: EmrSyncData | null;
}

export interface TreatmentPlanItem {
  variantSku: string;
  comment: string | null;
}

export interface ReviewData {
  reviewedBy: User;
  date: string;
  message: string;
  evaluationAndAction: string;
  emrSyncData: EmrSyncData | null;
}

export interface ActivationData {
  activatedBy: User | null;
  date: string;
}

export interface InternalNote extends BaseEntity {
  author: User;
  text: string;
}

export interface FollowUp extends BaseEntity {
  scheduledDate: string;
  type: FollowUpType;
  createdBy: User;
  notificationAttempts: string[];
  data: { event_id: string; event_type: string; form_response: Typeform.Response } | null;
  openedAt: string | null;
  reviewData: ReviewData | null;
  activationData: ActivationData | null;
  internalNote: string | null;
  readonly repliedAt: string | null;
}

export interface TreatmentConfigurationItemModifierValue {
  modifierId: number;
  modifierValue: any;
}

export interface TreatmentConfigurationItem {
  name: string;
  productId: number;
  variantId: number;
  sku: string;
  quantity: number;
  modifierValues: TreatmentConfigurationItemModifierValue[];
  price: number;
  isTaxable: boolean;
  isDigital: boolean;
}

export interface TreatmentConfiguration extends BaseEntity {
  purchaseOption: PurchaseOption;
  productItems: TreatmentConfigurationItem[];
  serviceFeeItem: TreatmentConfigurationItem;
}

export interface PharmacyContactData {
  contactedBy: User;
  date: string;
}

export interface OrderInfo {
  orderId: number;
  subscriptionOrderId: number | null;
  createdAt: string;
  resolutionData: ResolutionData | null;
  treatmentConfigurationId: string;
  pharmacyContactRequested: boolean | null;
  pharmacyContactData: PharmacyContactData | null;
  extraProductsRequest: string | null;
}

export interface OrderReference {
  cartId: string | null;
  treatmentConfigurationId: string;
  orderInfo: OrderInfo | null;
}

export interface QuestionnaireResultSummary {
  currentMedication?: string;
  currentDiseases?: string;
  adverseEffects?: string;
  allergies?: string;
}

export enum MeasurementType {
  Weight = 'weight',
}

export enum MeasurementSource {
  Manual = 'manual',
  CaseReport = 'caseReport',
  FollowUp = 'followUp',
}

export interface Measurement extends BaseEntity {
  date: string;
  value: number;
  type: MeasurementType;
  source: MeasurementSource;
}

export interface CaseReportAttributes {
  goalWeight?: number;
  bodyMassIndex?: number;
  zip?: string;
  weightLossMotivation?: string[];
}

export interface CaseReport extends BaseEntity {
  patient: User | null;
  diseaseCategory: DiseaseCategory;
  assignee: User | null;
  resolutionData: ResolutionData | null;
  questionnaireResult: { event_id: string; event_type: string; form_response: Typeform.Response };
  externalId: string;
  treatmentPlan: TreatmentPlanItem[];
  imageUploadFinished: boolean;
  submittedAt: string | null;
  needsAttention: boolean;
  followUps: FollowUp[];
  lead: Lead | null;
  treatmentConfigurations: TreatmentConfiguration[];
  currentTreatmentConfigurationId: string | null;
  orderReferences: OrderReference[];
  emrSyncData: EmrSyncData | null;
  completionData: CompletionData | null;
  internalNotes: InternalNote[];
  tags: string[];
  measurements: Measurement[];
  attributes: CaseReportAttributes;
  readonly isLegacyOnboarding: boolean;
  readonly questionnaireResultSummary: QuestionnaireResultSummary;
  readonly orders: OrderInfo[];
  readonly mediaItems: MediaItem[];
  readonly version: number;
  readonly currentTreatmentConfiguration: TreatmentConfiguration | null;
  readonly finalized: boolean;
  readonly submitted: boolean;
}
